import * as uuid from 'uuid';
import { ELAPSED_TIME_THRESHOLD, RESCAN_TIMEOUT, VISIBLE_AMOUNT_THRESHOLD } from './common.js';

type ProductListViewedEvent = WindowEventMap['product-list-viewed'];

interface ObservableProduct {
  element: HTMLElementTagNameMap['product-element'];
  emitted: boolean;
  lastScanEpoch?: number;
  rescanListener: ()=> void;
  scanTimerId?: ReturnType<typeof setTimeout>;
}

interface ProductList extends Partial<ProductListViewedEvent['detail']> {
  observableProducts: ObservableProduct[];
}

interface ViewedProductsQueue {
  [listIndex: number]: Partial<ViewableProduct>[];
}

const productLists = <ProductList[]>[];

const viewedProductsQueue = <ViewedProductsQueue>{};
let queueTimerId: ReturnType<typeof setTimeout>;

/**
 * When a collection page or page with product carousels loads, fire a product list viewed event if
 * the user has scrolled the list into view for at least a few seconds and if products are in view
 *
 * @param container element that contains products to observe
 * @param data contains collection meta data
 */
export function observeProductListViewConditionally(container: HTMLElement,
  data: Partial<ProductListViewedEvent['detail']> = {}) {
  const productElements = container.querySelectorAll<HTMLElementTagNameMap['product-element']>(
    'product-card > product-element');
  if (productElements.length === 0) {
    return;
  }

  const listIndex = productLists.length;
  viewedProductsQueue[listIndex] = [];

  const productList = {
    ...data,
    observableProducts: <ObservableProduct[]>[]
  };

  for (let i = 0; i < productElements.length; i++) {
    const element = productElements[i];

    const product = {
      element,
      emitted: false,
      lastScanEpoch: NaN,
      rescanListener: () => rescan(listIndex, i)
    };

    productList.observableProducts.push(product);

    addEventListener('scroll', product.rescanListener, { passive: true });
    addEventListener('product-element-list-carousel-scrolled', product.rescanListener,
      { passive: true });
  }

  productLists.push(productList);
}

/**
 * Checks if product is in view and if so adds it to the queue
 *
 * @param listIndex product list index
 * @param productIndex product index in the list
 */
function rescan(listIndex: number, productIndex: number) {
  const product = productLists[listIndex].observableProducts[productIndex];

  clearTimeout(product.scanTimerId);

  if (product.emitted) {
    teardown(listIndex, productIndex);
    return;
  }

  // If not carousel then use full width container
  const visibleContainer = product.element.closest<HTMLElement>('.splide') ?? document.body;
  const visibleAmount = getVisiblePercentageInsideContainer(product.element.parentElement,
    visibleContainer);

  if (visibleAmount < VISIBLE_AMOUNT_THRESHOLD) {
    product.lastScanEpoch = undefined;
    return;
  }

  if (!product.lastScanEpoch) {
    product.lastScanEpoch = Date.now();
    product.scanTimerId = setTimeout(product.rescanListener, RESCAN_TIMEOUT);
    return;
  }

  const elapsedMillis = Date.now() - product.lastScanEpoch;

  if (elapsedMillis < ELAPSED_TIME_THRESHOLD) {
    product.scanTimerId = setTimeout(product.rescanListener, RESCAN_TIMEOUT);
    return;
  }

  product.emitted = true;

  teardown(listIndex, productIndex);

  // Add product to the queue
  const viewedProduct = mapProduct(product.element);
  viewedProductsQueue[listIndex].push(viewedProduct);

  clearTimeout(queueTimerId);
  queueTimerId = setTimeout(flushEventQueue, RESCAN_TIMEOUT);
}

/**
 * Cleans up event listeners and timers for viewed product
 *
 * @param listIndex product list index
 * @param productIndex product index in the list
 */
function teardown(listIndex: number, productIndex: number) {
  const product = productLists[listIndex].observableProducts[productIndex];
  clearTimeout(product.scanTimerId);
  removeEventListener('scroll', product.rescanListener);
  removeEventListener('product-element-list-carousel-scrolled', product.rescanListener);
}

/**
 * Processes queue and emits product list viewed event
 */
function flushEventQueue() {
  for (const listIndex in viewedProductsQueue) {
    if (viewedProductsQueue[listIndex].length === 0) {
      continue;
    }

    const productList = productLists[listIndex];

    type Detail = WindowEventMap['product-list-viewed']['detail'];
    const event = new CustomEvent<Detail>('product-list-viewed', {
      detail: {
        event_id: uuid.v4(),
        handle: productList.handle,
        id: productList.id,
        name: productList.name,
        variants: viewedProductsQueue[listIndex]
      }
    });
    dispatchEvent(event);

    // Clear the queue
    viewedProductsQueue[listIndex] = [];
  }
}

function mapProduct(product: HTMLElementTagNameMap['product-element']) {
  const viewableProduct = <Partial<ViewableProduct>>{};
  viewableProduct.main_image = product.dataset.mainImage;
  viewableProduct.product_id = parseInt(product.dataset.id, 10);
  viewableProduct.product_title = product.dataset.title;
  viewableProduct.tags = parseTags(product.dataset.tags);
  viewableProduct.selected_variant_id = parseInt(product.dataset.selectedVariantId, 10);
  viewableProduct.type = product.dataset.type;
  viewableProduct.url = product.dataset.url;
  viewableProduct.yotpo_average = parseFloat(product.dataset.reviewsAverage);
  viewableProduct.yotpo_count = parseInt(product.dataset.reviewsCount, 10);

  // TODO: this does not make any sense, this overwrites properties, this needs to be refactored

  const variants = product.querySelectorAll('variant-element');
  for (const variant of variants) {
    viewableProduct.compare_at_price = parseInt(variant.dataset.compareAtPrice, 10);
    viewableProduct.currency = Shopify.currency?.active;
    viewableProduct.price = parseInt(variant.dataset.price, 10);
    viewableProduct.variant_id = parseInt(variant.dataset.id, 10);
    viewableProduct.variant_title = variant.dataset.title;
    viewableProduct.sku = variant.dataset.sku;
  }

  return viewableProduct;
}

/**
 * Returns the ratio of the visible portion of the given element to the element's total dimensions
 * within container.
 *
 * For example, if an element is 50% visible, this returns `0.50`.
 *
 * This handles the case for product carousels when element is not visible due to overflow
 */
function getVisiblePercentageInsideContainer(element: HTMLElement, container: HTMLElement) {
  const rect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  // Check if element outside of container
  if (rect.left > containerRect.right || rect.right < containerRect.left) {
    return 0;
  }

  const area = rect.width * rect.height;
  const clip = Math.max(
    (Math.min(containerRect.right, rect.right) - Math.max(containerRect.left, rect.left)) *
    (Math.min(innerHeight, rect.bottom) - Math.max(0, rect.top)), 0);

  return Math.round(100 * clip / area) / 100;
}

/**
 * Represents a product variant.
 */
interface ViewableProduct {
  /**
   * Compare at price. This is the MSRP price before markdown or reductions from discounts.
   */
  compare_at_price?: number;

  coupon?: string;

  currency: string;

  main_image?: string;

  position?: number;

  price: number;

  /**
   * Shopify product id
   */
  product_id: number;

  /**
   * Shopify product title
   */
  product_title: string;

  /**
   * Selected variant id
   */
  selected_variant_id: number;

  /**
   * Sku from selected or first available variant
   */
  sku: string;

  /**
   * Product tags
   */
  tags: string[];

  /**
   * The type of the product
   *
   * @example "hair-dryer"
   */
  type: string;

  /**
   * The path to the product.
   *
   * This could be a relative url or an absolute url.
   *
   * @example "/collections/curling-wands/products/le-duo-grande-black"
   */
  url: string;

  variant_id: number;
  variant_title: string;

  /**
   * Yotpo average rating from reviews (string containing decimal)
   */
  yotpo_average: number;

  /**
   * Number of Yotpo reviews
   */
  yotpo_count: number;
}

function parseTags(tags: string) {
  return tags?.split(' ').map(tag => tag.trim()).filter(tag => tag);
}
