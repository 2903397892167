import * as uuid from 'uuid';
import { ELAPSED_TIME_THRESHOLD, RESCAN_TIMEOUT, VISIBLE_AMOUNT_THRESHOLD } from './common.js';

/**
 * The entire cart drawer section, or the entire cart element on the cart page.
 */
let cart: HTMLElement | null | undefined;

/**
 * Tracks when the product first became visible. This is not the best name. Still thinking about
 * tracking cumulative time to handle interim hides. Not worried amount making it perfect yet so
 * this suffices.
 */
let lastScanEpoch: number | undefined = NaN;

/**
 * Internal timer id for rescheduling scans. We have to use a timer to handle the case where a
 * product enters into the viewport and is not followed by any additional user interaction, such as
 * a scroll event, because otherwise we have no way to impose a condition that the product has been
 * visible for some period of time.
 */
let scanTimerId: ReturnType<typeof setTimeout>;

/**
 * Whether a product list viewed event has been emitted during this page's lifetime. This is used to
 * prevent duplicate firing.
 */
let emitted = false;

/**
 * Observe views of upsell products.
 */
export function onCartUpsellItemsRendered(_event: WindowEventMap['cart-upsell-items-rendered']) {
  cart = document.getElementById('shopify-section-cart-drawer');

  const cartDrawer = document.querySelector('cart-drawer');
  if (cartDrawer) {
    if (cartDrawer.dataset.show === 'true') {
      // cart drawer already open - call the callback immediately
      onCartDrawerOpened();
    }

    addEventListener('cart-drawer-opened', onCartDrawerOpened);
    addEventListener('cart-drawer-closed', onCartDrawerClosed);
  } else {
    // the cart page, cart drawer is not present, but cart is displayed
    onCartDrawerOpened();
  }
}

/**
 * Look for whether the upsells list container has been sufficiently visible for a sufficient amount
 * of time. If sufficient, fire a product list viewed event.
 */
function rescan() {
  // This is hooked up to both setTimeout and scroll events. Clear in both cases. When the user
  // scrolls, we want to cancel the scheduled scan. When we are scanning, we want to clear any
  // existing scans.

  clearTimeout(scanTimerId);

  // Do nothing if we are done. There is some possible hanging around of scheduled rescans, or at
  // least, I am not sure if there are, so this is just to be safe.

  if (emitted) {
    teardown();
    return;
  }

  const upsellsContainer = document.querySelector('cart-upsell-items');
  if (!upsellsContainer) {
    // reset the time counter
    lastScanEpoch = undefined;

    return;
  }

  // In preparation for determining the visible portion of the upsells container, on pages with the
  // cart drawer, adjust the relative area to account for the overlapping cart totals section. If
  // there is no cart drawer, default to the full viewport height.

  let adjustedHeight;
  if (cart) {
    const totalsElement = document.querySelector<HTMLElement>('cart-element .info');
    adjustedHeight = Math.max(innerHeight - (totalsElement?.offsetHeight || 0), 0);
  }

  const visibleAmount = getVisiblePercentage(upsellsContainer, undefined, adjustedHeight);

  // If the upsells element is not sufficiently visible, stop. We do not schedule another scan
  // here because it is impossible for the element to become less occluded until the user interacts
  // via scroll/resize/zoom or the dom mutates.

  if (visibleAmount < VISIBLE_AMOUNT_THRESHOLD) {
    lastScanEpoch = undefined;
    return;
  }

  // If we do not know the last scan time, then the upsells container just now became visible.
  // Capture that fact and schedule another scan.

  if (!lastScanEpoch) {
    lastScanEpoch = Date.now();
    scanTimerId = setTimeout(rescan, RESCAN_TIMEOUT);
    return;
  }

  const elapsedMillis = Date.now() - lastScanEpoch;

  // If the upsells container is still sufficiently visible but an insufficient amount of time has
  // elapsed, schedule another scan.

  if (elapsedMillis < ELAPSED_TIME_THRESHOLD) {
    scanTimerId = setTimeout(rescan, RESCAN_TIMEOUT);
    return;
  }

  // We met all the conditions to consider the upsells list viewed.

  emitProductListViewed();
}

/**
 * Stop watching the dom and user interactions.
 */
function teardown() {
  clearTimeout(scanTimerId);
  cart?.removeEventListener('scroll', rescan);
  removeEventListener('scroll', rescan);
  removeEventListener('cart-drawer-opened', onCartDrawerOpened);
  removeEventListener('cart-drawer-closed', onCartDrawerClosed);
}

/**
 * Handle the event when the cart drawer, or main cart on the cart page on cart page load, becomes
 * visible.
 */
function onCartDrawerOpened(_event?: Event) {
  // The user may be opening and closing the cart multiple times and we may not have correctly
  // unregistered the listeners. If we have already emitted an event then we are done.

  if (emitted) {
    teardown();
    return;
  }

  // Immediately scan to account for elements fully in view without scrolling.

  rescan();

  // On pages with the cart drawer, rescan when the user scrolls the drawer. Otherwise, rescan when
  // the viewport scrolls.

  if (cart) {
    // prevent duplicate registration
    cart.removeEventListener('scroll', rescan);
    cart.addEventListener('scroll', rescan, { passive: true });
  } else {
    // prevent duplicate registration
    removeEventListener('scroll', rescan);
    addEventListener('scroll', rescan, { passive: true });
  }
}

/**
 * When the cart drawer is hidden, stop observing scroll events.
 */
function onCartDrawerClosed(_event?: Event) {
  // onCartHidden is only a registered listener when the cart drawer is present. On pages like the
  // cart page where there is no cart drawer the cart cannot be hidden. cartSection is guaranteed to
  // be defined here, and we do not need to worry about removing the scroll listener for the cart
  // page.
  cart.removeEventListener('scroll', rescan);
}

function emitProductListViewed() {
  const upsells = document.querySelectorAll('cart-upsell-item');

  // This gets called even when there are no upsells, because the caller logic only looks for the
  // percentage of the container viewed, not each item.

  if (upsells.length === 0) {
    return;
  }

  const variants: Partial<ViewableProduct>[] = [];
  for (const element of upsells) {
    const variant = <Partial<ViewableProduct>>{};
    variant.compare_at_price = parseInt(element.dataset.compareAtPrice, 10);
    variant.currency = element.dataset.currencyCode;
    variant.price = parseInt(element.dataset.price, 10);
    variant.product_id = parseInt(element.dataset.id, 10);
    variant.product_title = element.dataset.productTitle;
    variant.sku = element.dataset.sku;
    variant.variant_title = element.dataset.variantTitle;
    variant.type = element.dataset.type;
    variant.variant_id = parseInt(element.dataset.variantId, 10);
    variants.push(variant);
  }

  type ProductListViewedEvent = WindowEventMap['product-list-viewed'];
  type Detail = ProductListViewedEvent['detail'];
  const detail: Detail = {
    event_id: uuid.v4(),
    variants
  };

  // Distinguish between users viewing upsells on the cart page and users viewing upsells in the
  // drawer. Viewing upsells in the drawer signals the user exerted more effort.

  if (location.pathname.startsWith('/cart')) {
    detail.handle = 'cart-page-upsells';
    detail.name = 'Cart Page Upsells';
  } else {
    detail.handle = 'cart-drawer-upsells';
    detail.name = 'Cart Drawer Upsells';
  }

  emitted = true;

  const event = new CustomEvent<Detail>('product-list-viewed', { detail });
  dispatchEvent(event);

  teardown();
}

/**
 * Returns the ratio of the visible portion of the given element to the element's total dimensions.
 *
 * For example, if an element is 50% visible, this returns `0.50`.
 *
 * This does not account for overlapping elements. For example, if there is an overlapping element
 * with a fixed position and an effectively higher z-index, the occluded portion of the element is
 * still considered visible.
 */
function getVisiblePercentage(element: HTMLElement, containerWidth?: number,
  containerHeight?: number) {
  const effectiveContainerWidth = typeof containerWidth === 'undefined' ?
    innerWidth :
    containerWidth;

  const effectiveContainerHeight = typeof containerHeight === 'undefined' ?
    innerHeight :
    containerHeight;

  const rect = element.getBoundingClientRect();
  const area = rect.width * rect.height;
  const clip = Math.max((Math.min(effectiveContainerWidth, rect.right) - Math.max(0, rect.left)) *
    (Math.min(effectiveContainerHeight, rect.bottom) - Math.max(0, rect.top)), 0);
  return Math.round(100 * clip / area) / 100;
}

/**
 * Represents a product variant.
 */
interface ViewableProduct {
  /**
   * Compare at price. This is the MSRP price before markdown or reductions from discounts.
   */
  compare_at_price?: number;

  coupon?: string;

  currency: string;

  main_image?: string;

  position?: number;

  price: number;

  /**
   * Shopify product id
   */
  product_id: number;

  /**
   * Shopify product title
   */
  product_title: string;

  /**
   * Selected variant id
   */
  selected_variant_id: number;

  /**
   * Sku from selected or first available variant
   */
  sku: string;

  /**
   * Product tags
   */
  tags: string[];

  /**
   * The type of the product
   *
   * @example "hair-dryer"
   */
  type: string;

  /**
   * The path to the product.
   *
   * This could be a relative url or an absolute url.
   *
   * @example "/collections/curling-wands/products/le-duo-grande-black"
   */
  url: string;

  variant_id: number;
  variant_title: string;

  /**
   * Yotpo average rating from reviews (string containing decimal)
   */
  yotpo_average: number;

  /**
   * Number of Yotpo reviews
   */
  yotpo_count: number;
}

for (const event of cvo_event_queue) {
  if (event.type === 'cart-upsell-items-rendered') {
    onCartUpsellItemsRendered(event);
  }
}

addEventListener('cart-upsell-items-rendered', onCartUpsellItemsRendered);
