import { observeProductListViewConditionally } from './product-list.js';

/**
 * When a collection page or page with product carousels load fire a product list viewed event
 * conditionally for each product list
 */
function onDOMContentLoaded(_event: Event) {
  const collections = scrapeCollections();

  for (const collection of collections) {
    observeProductListViewConditionally(collection.container, {
      handle: collection.handle,
      id: collection.id,
      name: collection.name
    });
  }
}

/**
 * Represents a list of products and collection meta data.
 */
export interface Collection {
  /**
   * Element that contains products to observe
   */
  container: HTMLElement;

  /**
   * Shopify collection handle
   */
  handle: string;

  /**
   * Shopify collection id
   */
  id: number;

  /**
   * Shopify collection title
   */
  name: string;
}

/**
 * Gathers a list of collections displayed by scraping the DOM.
 */
function scrapeCollections() {
  const selector = '.Collection__products-page, .js-carousel-products';
  const list = document.querySelectorAll<HTMLElement>(selector);
  return Array.from(list).map<Partial<Collection>>(element => ({
    handle: element.dataset.handle,
    id: parseInt(element.dataset.id, 10),
    name: element.dataset.name,
    container: element
  }));
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
